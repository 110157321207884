import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/src/theme/saasModern';
import React, { Fragment } from 'react';
import SEO from '../../../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../../../containers/SaasModern/sassModern.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../../../containers/SaasModern/Navbar';
import Footer from '../../../containers/SaasModern/Footer';
import Container from 'common/src/components/UI/Container';
import Box from 'common/src/components/Box';
import DummyBanner from '../../../containers/SaasModern/Banner/DummyBanner';

export default () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="Affiliate Program Terms"/>

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>
          <DummyBanner titleText="Affiliate Program Terms"/>

          <Container>
            <Box style={{ margin: '30px' }}>
              <p>
                Thank you for your interest in the B2BSaaSLeads Affiliate Program! This agreement is
                designed to protect you, B2BSaaSLeads and our customers. Please read thoroughly. If
                you
                have any questions, please contact us at <a
                href="mailto:hello@B2BSaaSLeads.com">hello@B2BSaaSLeads.com</a>.
              </p>
              <p>
                This agreement describes the terms and conditions for participation in the
                B2BSaaSLeads
                Affiliate Program. In this agreement, the term "Affiliate" refers to you (the
                applicant). In this agreement, “B2BSaaSLeads” refers to B2BSaaSLeads Inc., with
                whom you are entering this agreement. By applying to the B2BSaaSLeads Affiliate
                Program
                you are confirming that you have read the agreement and agree to the terms and
                conditions.
              </p>
              <h2>Commissions</h2>
              <p>
                For a sale to generate a commission to an Affiliate, the customer must complete the
                payment for one of the services provided on B2BSaaSLeads. Commissions will only be
                paid on sales that
                are made when the customer clicks through qualified, correctly structured
                Affiliate links. Properly coded links are the sole responsibility of the
                affiliate. Commissions can be viewed on the Affiliate Dashboard.
              </p>
              <p>
                The commissions accumulate with recurring payments made by a customer, limited to
                the first 12 months within the first 12 months.
              </p>
              <h2>Payment</h2>
              <p>
                To give time for refunds, the commissions are payable 45 days after the payment
                made by a customer. The commissions are then due when the Affiliates refers at least
                two such paying customers. The minimum payout amount may vary upon the Affiliate's
                choice of payout method.
              </p>
              <p>
                The commissions due are paid out via PayPal on the first Monday of each month.
              </p>
              <h2>Refunds</h2>
              <p>
                In the event a customer requests a refund for a transaction for which the
                Affiliate has earned commissions, any commissions earned on the refund amount will
                be deducted from the Affiliate’s balance.
              </p>
              <h2>Usage and Obligations</h2>
              <p>
                Affiliates are permitted to use the B2BSaaSLeads brand and marketing resources
                available
                in the <a href="/press">Press</a> section of B2BSaaSLeads website. Logos and other
                assets cannot be modified. The Affiliate does not gain any trademark, copyright or
                any other rights to these materials.
              </p>
              <p>
                The Affiliate will never imply that they are acting on behalf of B2BSaaSLeads. The
                Affiliate will never represent themselves, B2BSaaSLeads or their relationship with
                B2BSaaSLeads in a false or misleading way.
              </p>
              <p>
                The Affiliate will never use advertising to promote directly their Affiliate
                links. The Affiliate will never bid for advertisements that compete with B2BSaaSLeads.
              </p>
              <p>
                The Affiliate will not engage in the distribution of an unsolicited bulk email
                mentioning or referencing B2BSaaSLeads.
              </p>
              <p>
                The Affiliate is not permitted to refer themselves or someone in their company or
                office.
              </p>
              <p>
                The Affiliate is not permitted to use their own affiliate links themselves to
                purchase any service offered by B2BSaaSLeads. Doing so will lead to an immediate ban
                and suspension of the affiliate from the B2BSaaSLeads Affiliate Program.
              </p>
              <h2>Termination</h2>
              <p>
                Either party has the right to terminate the agreement immediately without prior
                notice.
              </p>
              <p>
                If the Affiliate terminates the agreement, no further commissions from B2BSaaSLeads
                will
                be paid for any past or future customer transactions.
              </p>
              <p>
                If B2BSaaSLeads chooses to terminate the agreement, the remaining balance will be paid
                to the affiliate within 60 days of termination.
              </p>
              <p>
                In case B2BSaaSLeads terminates the agreement for violation of the terms and
                conditions,
                the commissions will not be paid or owed.
              </p>
              <h2>Governing Law</h2>
              <p>
                This Agreement shall be governed, construed, and enforced in accordance with the
                laws of the State of Delaware.
              </p>
              <h2>Modification</h2>
              <p>
                We may modify any of the terms and conditions within this agreement at any time
                and at our sole discretion. These modifications may include, but not limited to
                changes in the scope of available commissions, commission schedules, payment
                procedures and Affiliate Program rules.
              </p>
              <p>
                Last modified: November 10th, 2020
              </p>

            </Box>
          </Container>
          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
